import React, { useState } from "react";
import "./newsletter.css"; // Import the CSS file for styling

export const NewsLetter = (props) => {
  const { data } = props;

  if (!props.data) {
    return <div>Loading...</div>;
  }
  return (
    <div id="news-letter" className="text-center">
      <div className="container">
        <div className="col-md-12 text-center section-title">
          <h2>News Letter</h2>
          <h3>{props.data ? props.data.title : "Loading"}</h3>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="feature-content">
              <div className="d-flex mt-30">
                <div className="text-container-sym pr-10">
                  <h3>This issue contains </h3>

                  <p>An Appeal! </p>
                  <p>{data.appeal}</p>
                  <p>Research Spotlight</p>
                  <p>{data.researchspotlight}</p>
                  <p>Research Highlight</p>
                  <p>{data.researchhighlight}</p>
                  <p>Event Report</p>
                  <p>{data.eventreport}</p>
                  <p className="red-color">Download</p>

                  <h3>Contact Us </h3>
                  <p>
                    Feel free to write to us For any information about ISAJ and
                    membership: isaj[at]gmail.com To subscribe to our
                    newsletter: isaj.newsletter[at]gmail.com
                  </p>
                </div>
                <div className="image-container-sym">
                  <img src={data.image} alt={data.title} />
                  <div className="div-center">
                  <a href={data.url} className="btn-custom-download" target="_blank">
              Download
            </a>
            </div>
                </div>
              </div>
            </div>
            <div>
            <a href="/newsletters" className="btn btn-custom-more btn-lg page-scroll">
              See previous versions
            </a>
            </div>
            {" "}
          </div>
        </div>
      </div>
    </div>
  );
};
