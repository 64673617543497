import React from "react";

export const About = (props) => {
  return (
    <div id="about" className="">
    <div className="container">
      <div className="col-md-12 text-center section-title">
      <h2>About Us</h2>
        </div>
        <div className="col-xs-12 col-md-12">
          <div className="about-text">
          <p dangerouslySetInnerHTML={{ __html: props.data ? props.data.paragraph : "loading..." }} />
          </div>
        </div>
      </div>
    </div>
  );
};
