import React from "react";

export const Membership = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>ISAJ Membership</h2>
        </div>
        <div className="row">
          <div>
            <p>We invite you to join as an official member of the Indian Scientists Association in Japan (ISAJ). You might be aware that ISAJ was registered as a non-profit organization (NPO) in Ibaraki Prefecture of Japan on July 27, 2010. ISAJ was founded in 2008 to:</p>
            <p><ul>
              <li>1. bring all Indian scientists and researchers working in Japan into a global platform and promote a career network</li>
              <li>2. promote S&T cooperation between Japan and India by facilitating greater interaction between Indian scientists and researchers and the scientific community in Japan</li>
              <li>3. formulate strategy to cultivate the next generation of Indian scientists and scholars through close interaction with their counterparts in Japan and other countries</li>
              <li>4. share experiences about Japanese culture and way of life for the benefit of prospective researchers from India who would like to pursue their academic/research careers in Japan</li>
              <li>5. organize scientific meetings, symposia, conferences and disseminate information about the research activities pursued by the Indian and Japanese scientific communities through scientific publications</li>
            </ul>
            </p>
            <p>Membership is available to any individual who is a short-term or long-term resident of Japan and who has an interest in any or all branches of science and technology as evidenced by professional affiliation in any scientific or technological society/academic institution of higher learning, or by professional activity.</p>
            
            <p>General membership is available under three categories:
            <ul>
              <li><strong>Regular Member:</strong> Available for residents of Japan who want to become a member of ISAJ for a period of one year. The annual membership fee is 5,000 Yen.</li>
              <li><strong>Student Member:</strong> Available for residents in Japan seeking a master’s, doctorate, or equivalent level degree who want to become a member of ISAJ for a period of one year. The annual membership fee is 2,000 Yen.</li>
              <li><strong>Life Member:</strong> Available for long-term residents in Japan who want long-term/lifelong association with ISAJ. The one-time membership fee is 50,000 Yen.</li>
            </ul>
            </p>
            <p><strong>Supporting Membership:</strong> Available to individuals who have an interest in the goals of ISAJ and wish to provide financial contributions of not less than 20,000 Yen annually. Supporting members will be treated as general members of ISAJ without having the right to vote. (General Members can also provide additional financial contributions under this category over and above the regular membership dues).</p>
            
            <p><strong>Institutional/Corporate/Patron Membership:</strong> Available to organizations and companies who wish to subscribe annually as Corporate/Institutional/Patron sponsors (please contact ISAJ office for information regarding sponsorship/subscription rates).</p>
            
            <p>Membership dues support costs associated with carrying out the missions of ISAJ, including meetings, symposia, conference planning, elections, publications, website maintenance, communication, and management expenses. The full membership fee is paid upon joining along with the admission fee (¥1000), which is payable only once at the time of joining the society for the first time. Upon payment, members will receive all benefits as members of ISAJ to which they are entitled for that membership period.</p>
            
            <p>Please find attached the Membership Application Form as MS Word and PDF files. Please fill out the application under whichever membership category you fit in and mail the completed application form to:</p>
            <p><strong>The Chairman, ISAJ,</strong><br />
              Nagakuni Dai 3-24, Tsuchiura-shi, Ibaraki-ken, 300-0810 Japan<br />
              or by Email: chairman@isaj.jp
            </p>
            
            <p>Please join to make ISAJ a robust forum for the benefit of Indian researchers/scientists working in Japan and also for the future of Japan-India scientific and technological collaboration.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
