import React from "react";
import './allnewsletters.css'; // Import the CSS file for styling

export const AllNewsLetters = (props) => {
  const { data } = props;
  console.log('data', props);
  if (!data || data.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div id="features" className="text-center section-title">
      <div className="container">
        <div className="col-md-12 text-center mt-90">
          <h2>News Letters</h2>
        </div>
        <div className="col-md-12">
          <div className="d-flex flex-wrap">
            {data.map((d, i) => (
              <div key={i} className="symposium-item">
                <div className="image-container-abc m-0">
                  <img src={d.image} alt={d.title} className="img-responsive" />
                </div>
                <div className="text-left details">
                  <p className="mb-2" ><strong>Title:</strong> {d.title}</p>
                  <p className="mb-2"><strong>Theme:</strong> {d.theme}</p>
                  <p className="mb-2"><strong>Venue:</strong> {d.venue}</p>
                  <p className="mb-2"><strong>Date:</strong> {d.date}</p>
                  <p className="mb-2"><strong>Conveners:</strong> {d.conveners}</p>
                  <p className="mb-2"><strong>Link:</strong> {d.link}</p>
                </div>
                
              </div>
              
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
