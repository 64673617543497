import React from "react";
import './allsymposium.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';

export const AllSymposium = (props) => {
  const { data } = props;
  const navigate = useNavigate(); // For navigation within the app

  if (!data || data.length === 0) {
    return <div>Loading...</div>;
  }
  const handleClick = (link) => {
    if (link.startsWith('/')) {
      navigate(link); // Internal navigation
    } else {
      window.location.href = link; // External navigation
    }
  };

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-12 text-center mt-90 section-title">
          <h2>Past Symposiums</h2>
        </div>
        <div className="col-md-12">
          <div className="d-flex flex-wrap">
            {data.map((d, i) => (
              <div key={i} className="symposium-item" onClick={() => handleClick( '/symposiums?id=' + d.number)}>
                <div id="click" className="image-container-abc m-0">
                  <img src={d.picture} alt={d.title} className="img-responsive" />
                </div>
                <div className="text-left details">
                  <p className="mb-2" ><strong>Title:</strong> {d.title}</p>
                  <p className="mb-2"><strong>Theme:</strong> {d.theme}</p>
                  <p className="mb-2"><strong>Venue:</strong> {d.venue}</p>
                  <p className="mb-2"><strong>Date:</strong> {d.date}</p>
                  <p className="mb-2"><strong>Conveners:</strong> {d.conveners}</p>
                  <p className="mb-2"><strong>Link:</strong> {d.link}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
