import React, { useState, useEffect } from "react";
import "./symposium.css"; // Import the CSS file for styling

export const SymposiumDetailed = ({ data, currentIndex }) => {
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    if (data) {
      // Find the item by the "number" property
      let item = data.find((item) => item.number === currentIndex);
      // If item is not found or currentNumber is null, default to the first item
      if (!item) {
        item = data[0];
      }
      setCurrentItem(item);
    }
  }, [currentIndex, data]);

  // Check if data is available and currentItem is set
  if (!data || !Array.isArray(data) || data.length === 0 || !currentItem) {
    return <div>Loading...</div>;
  }

  const nextItem = () => {
    const currentIndex = data.findIndex(
      (item) => item.number === currentItem.number
    );
    if (currentIndex < data.length - 1) {
      setCurrentItem(data[currentIndex + 1]);
    }
  };

  const prevItem = () => {
    const currentIndex = data.findIndex(
      (item) => item.number === currentItem.number
    );
    if (currentIndex > 0) {
      setCurrentItem(data[currentIndex - 1]);
    }
  };

  return (
    <div>
      <div id="symposium" className="text-center">
        <div className="container">
          <div className="col-md-12 text-center section-title">
            <h2>{currentItem.title}</h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex mt-30">
                <div className="image-container-sym">
                  <img src={currentItem.picture} alt={currentItem.title} />
                </div>
                <div className="text-container-sym">
                  <p>{currentItem.details}</p>
                  <p>{currentItem.date}</p>

                  <span className="span">The scope of the symposium:</span>
                  <br></br>
                  <p className="">
                    All fields of science and technology, including, but not
                    limited to:
                    <ul>
                      {currentItem.scope.map((d, i) => (
                        <li key={i}>{d}</li>
                      ))}
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            {/* <a
            href="/symposia"
            className="btn btn-custom-symp-more btn-lg page-scroll"
          >
            All symposia
          </a> */}
          </div>
          <div className="left">
            <span className="span">Downloads</span>
            <br></br>
            <p>
              <ul>
                <li>
                  <a
                    href={currentItem["abstract-booklet-url"]}
                    className=""
                    download
                  >
                    Abstract Booklet
                  </a>
                </li>
                <li>
                  <a href={currentItem["symposium-program-url"]} className="">
                    Symposium Program
                  </a>
                </li>
              </ul>
            </p>
            <p className="text-danger">
              The deadline for submission of the abstract is{" "}
              {currentItem["abstract-submission-date"]}.
            </p>

            {currentItem.fee && (
              <>
                <span className="span">Registration Fee</span>
                <p>
                  <ul>
                    <li>Student attendees: {currentItem.fee.students} Yen</li>
                    <li>
                      Non-Student attendees: {currentItem.fee["non-students"]}{" "}
                      Yen
                    </li>
                  </ul>
                </p>
              </>
            )}

            <a
              className="span left"
              href={currentItem["registration-url"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click for Registration
            </a>
          </div>
        </div>
        <div className="div-import">
          <h2></h2>
          <p className="left">
            <span className="bold-text">Honorary Patron: </span>
            {currentItem.patron}
          </p>
          <p className="left">
            <span className="bold-text">Honorary Advisor: </span>
            {currentItem.advisor}
          </p>
          <p className="left">
            <span className="bold-text">Honorary Conveners: </span>
            {currentItem.conveners}
          </p>
          <p className="left">
            <span className="bold-text">Honorary Organizing Committee: </span>
            {currentItem.committee}
          </p>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; All rights reserved ©ISAJ.ORG | Developed by{" "}
            <a
              href="https://www.linkedin.com/in/ankitkumarsharma/"
              rel="nofollow"
            >
              Ankit Sharma
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
