import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Symposium } from "./components/symposium";
import { About } from "./components/about";
import { NewsLetter } from "./components/newsletter";
import { Gallery } from "./components/gallery";
import { SymposiumDetailed } from "./components/symposium-detailed";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import { AllSymposium } from "./components/allsymposium";
import "./App.css";
import { AllNewsLetters } from "./components/allnewsletters";
import { Membership } from "./components/membership";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    fetch("/data/data.json")
      .then((response) => response.json())
      .then((data) => setLandingPageData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const getQueryParam = (param, location) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  const SymposiumWithQuery = () => {
    const location = useLocation();
    const id = getQueryParam("id", location);
    const currentIndex = id ? parseInt(id, 10) : 0;

    return (
      <div>
        <Navigation />
        <SymposiumDetailed data={landingPageData.Symposiums} currentIndex={currentIndex} />
      </div>
    );
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/symposiums"
          element={<SymposiumWithQuery />}
        />
        <Route
          path="/symposia"
          element={
            <div>
              <Navigation />
              <AllSymposium data={landingPageData.Symposiums} />
            </div>
          }
        />
        <Route
          path="/newsletters"
          element={
            <div>
              <Navigation />
              <AllNewsLetters data={landingPageData.NewsLetters} />
            </div>
          }
        />
        <Route
          path="/"
          element={
            <div>
              <Navigation />
              {/* <Header data={landingPageData.Header} /> */}
              <Symposium data={landingPageData.Symposiums} />
              <NewsLetter data={landingPageData.NewsLetter} />
              <About data={landingPageData.About} />
              <Membership data={landingPageData.Membership} />
              {/* <Gallery data={landingPageData.Gallery} /> */}
              {/* <Team data={landingPageData.Team} /> */}
              <Contact data={landingPageData.Contact} />
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
